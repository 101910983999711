<template>
	<div class="text-center">
		<v-dialog
			v-model="showDialog"
			max-width="480"
			origin="top center"
			class="dialog pa-0"
			persistent
			scrollable
		>
			<v-card :loading="isSaving">

				<v-card-text class="pa-8">
          <v-icon size="58" color="orange">mdi-alert-circle-outline</v-icon>
          <h1 class="header-1 mt-4">{{ $t('received') }}</h1>
					{{ $t('billing_mark_receive_message') }} "{{ formData ? formData.document_number : '' }}"?

          <div class="mt-8">
            <v-btn
              class="mr-2 white--text text-capitalize"
              color="#0171a1"
              depressed
              large
              @click="onMarkAsReceived"
							:loading="isSaving"
            >
              {{ $t('billing_mark_receive') }}
            </v-btn>
            <v-btn
              outlined
              large
              depressed
              class="teal--text text-capitalize"
              @click="onClose"
							:disabled="isSaving"
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
				</v-card-text>

			</v-card>
			<v-snackbar
				timeout="2000"
				vertical
				:color="snackbarOption.color"
				v-model="showSnackbar"
				bottom
			>
			<v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
			</v-snackbar>

		</v-dialog>
	</div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
	props: ["open", "formData"],

	data() {
		return {
			isSaving: false,
			errorMessage: '',
			showSnackbar: false,
			snackbarOption: {
				icon: '',
				message: '',
				color: ''
			},
		}
	},

	computed: {
		showDialog: {
			get() {
				return this.open;
			},
			set(value) {
				this.$emit("toggle", value);
			},
		},
	},

	methods: {

		...mapActions('accounting', ['billReceived']),

		async onMarkAsReceived() {
			if(this.isSaving) {
				return;
			}
			this.showSnackbar = false;
			this.isSaving = true;
			try {

				const { data } = await this.billReceived(this.formData.id);

				this.isSaving = false;
				this.$emit("toggle", null, { received: true, message: data.message });
			} catch(error) {
				
				const { data } = error.response || { data: {  } };

				this.snackbarOption = {
					icon: 'mdi-alert-circle',
					color: 'green',
					message: data.message || "Could not save the data.",
				};
				this.showSnackbar = true;
				this.isSaving = false;
			}
		},


		onClose() {
			this.$emit("toggle");
		},

	},
};
</script>

<style lang="scss" scoped>
	.dialog {
		z-index: 9999;
	}
</style>