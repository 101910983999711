<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="80%"
      origin="top center"
      class="dialog pa-0"
	  persistent
    >
      <v-card>
        <v-card-title class="pa-0 z-index-front">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>{{ $t('pay_bills') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
					<v-container class="grey lighten-4 pt-1">
						<v-form>
							<v-row
								no-gutters
							>
								<v-col
									cols="3"
									class="pl-4"
								>
									<v-card
										class="pa-2 grey lighten-4"
										outlined
										tile
									>

									<label class="form-label text-uppercase" for="formdata-vendor">{{ $t('vendor') }}</label>
									<v-select
										v-model="formData.vendor"
										:items="vendorData"
										:rules="[v => !!v || $t('field_required')]"
										id="formdata-vendor"
										:label="$t('select_vendor')"
										solo
										flat
										class="app-theme-input-border"
										dense
										@change="onChangeVendor"
									>
									</v-select>

									<label class="form-label text-uppercase" for="formdata-bill-date">{{ $t('payment_date') }}</label>
									<v-menu
										ref="paymentDateMenu"
										v-model="paymentDateMenu"
										:close-on-content-click="false"
										transition="scale-transition"
										max-width="290px"
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="formData.paymentDate"
												:label="$t('date')"
												persistent-hint
												append-icon="mdi-calendar"
												v-bind="attrs"
												v-on="on"
												id="formdata-bill-date"
												solo
												class="app-theme-input-border"
												dense
												outlined
												flat
											></v-text-field>
										</template>
										<v-date-picker
											v-model="formData.paymentDate"
											no-title
											@input="paymentDateMenu = false"
										></v-date-picker>
									</v-menu>

									<label class="form-label text-uppercase" for="formdata-currency">{{ $t('currencyname') }}</label>
									<v-select
										v-model="formData.currency"
										:items="currencyData"
										:rules="[v => !!v || $t('field_required')]"
										id="formdata-currency"
										:label="$t('select_currency')"
										solo
										flat
										class="app-theme-input-border"
										dense
									>
									</v-select>

									<label class="form-label text-uppercase" for="formdata-payment-account">{{ $t('payment_account') }}</label>
									<v-select
										v-model="formData.paymentAccount"
										:items="paymentAccountData"
										:rules="[v => !!v || $t('field_required')]"
										id="formdata-payment-account"
										:label="$t('select_payment_account')"
										solo
										flat
										class="app-theme-input-border"
										dense
									>
									</v-select>

									<div class="text-right">
										<span class="form-label text-uppercase">{{ $t('balance') }}:</span> <strong>{{ balanceAmountFormatted }}</strong>
									</div>

									</v-card>
								</v-col>
								<v-col
									class="white pr-4 pl-4"
								>
									<v-card
										class="pa-2"
										flat
									>

									<v-data-table
										:headers="tableHeaders"
										:items="payeeTableData"
										hide-default-footer
										items-per-page="100"
										sort-by="calories"
										class="elevation-1 v-table-middle-align"
										:single-select="false"
										show-select
										v-model="selectedPayee"
										:search="searchText"
									>
										<template v-slot:top>
											<div class="d-flex justify-end mb-4">
												<v-menu
													:close-on-content-click="false"
													offset-y
													class="white"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-btn v-bind="attrs" v-on="on" outlined text	height="42px">
															<v-icon>mdi-filter-menu-outline</v-icon>
															{{ $t('filters') }}
														</v-btn>
													</template>
													<v-list>
														<v-list-item>
														<span>{{ $t('due_date') }}</span>
														</v-list-item>
														<v-list-item>
															<v-row>
																<v-col
																	cols="12"
																	lg="6"
																>
																	<label class="text-capitalize" for="filter-from-date">{{ $t('from') }}</label>
																	<v-menu
																		ref="dateFromMenu"
																		v-model="dateFromMenu"
																		:close-on-content-click="false"
																		transition="scale-transition"
																		max-width="290px"
																		min-width="auto"
																	>
																		<template v-slot:activator="{ on, attrs }">
																			<v-text-field
																				v-model="dateFrom"
																				append-icon="mdi-calendar"
																				id="filter-from-date"
																				v-bind="attrs"
																				v-on="on"
																				solo
																				dense
																				class="app-theme-input-border"
																				flat
																				hide-details
																				style="width: 150px;"
																			></v-text-field>
																		</template>
																		<v-date-picker
																			v-model="dateFrom"
																			no-title
																			@input="dateFromMenu = false"
																		></v-date-picker>
																	</v-menu>
																</v-col>
																<v-col
																	cols="12"
																	lg="6"
																>
																	<label class="text-capitalize" for="filter-to-date">{{ $t('to') }}</label>
																	<v-menu
																		ref="dateToMenu"
																		id="filter-to-date"
																		v-model="dateToMenu"
																		:close-on-content-click="false"
																		transition="scale-transition"
																		max-width="290px"
																		min-width="auto"
																	>
																		<template v-slot:activator="{ on, attrs }">
																			<v-text-field
																				v-model="dateTo"
																				append-icon="mdi-calendar"
																				v-bind="attrs"
																				v-on="on"
																				solo
																				dense
																				class="app-theme-input-border"
																				flat
																				hide-details
																				style="width: 150px;"
																			></v-text-field>
																		</template>
																		<v-date-picker
																			v-model="dateTo"
																			no-title
																			@input="dateToMenu = false"
																		></v-date-picker>
																	</v-menu>
																</v-col>
															</v-row>
														</v-list-item>
														<div>&nbsp;</div>
													</v-list>
												</v-menu>
												<div class="search-field-wrapper">
													<v-text-field
														:label="$t('search_bill')"
														prepend-inner-icon="mdi-magnify"
														clearable
														hide-details
														flat
														solo
														class="mx-2 app-theme-input-border"
														dense
														v-model="searchText"
														height="41"
													></v-text-field>
												</div>
											</div>
										</template>
										<template v-slot:item.payment="{ item }">
											<v-text-field
												v-if="selectedPayee.find(record => record.id === item.id)"
												v-model="item.payment"
												solo
												dense
												outlined
												flat
												class="text-right app-theme-input-border"
												hide-details
											></v-text-field>
											<v-text-field
												v-else
												solo
												dense
												outlined
												flat
												disabled
												hide-details
												class="app-theme-input-border"
											></v-text-field>
										</template>

									</v-data-table>

										<v-row class="mt-4">
											<v-col cols="8">
											</v-col>
											<v-col>
												<v-row>
													<v-col class="text-right">{{ $t('subtotal') }}</v-col>
													<v-col class="text-right">${{ subTotal }}</v-col>
												</v-row>
												<hr>
												<v-row>
													<v-col class="text-right">{{ $t('tax') }}</v-col>
													<v-col class="text-right">${{ taxTotal }}</v-col>
												</v-row>
												<hr>
												<v-row>
													<v-col class="text-right font-weight-bold">{{ $t('total_payment_amount') }}</v-col>
													<v-col class="text-right font-weight-bold">${{ subTotal + taxTotal }}</v-col>
												</v-row>
											</v-col>
										</v-row>
																				
									</v-card>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-start">
          <v-btn @click="onSaveForm" class="text-capitalize btn-primary">{{ $t('pay') }}</v-btn>
					<v-btn outlined text class="text-capitalize" @click="onClose">{{ $t('cancel') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["open"],
	data() {
		return {
			formData: {},
			vendorData: [{
				value: 1,
				text: 'JD Logistics'
			}],
			dateFromMenu: '',
			dateToMenu: '',
			dateTo: '',
			dateFrom: '',
			dateToMenu: '',
			currencyData: [],
			paymentAccountData: [],
			paymentDateMenu: false,
			selectedPayee: [],
			payeeTableData: [],
			balanceAmount: 123456,
			searchText: '',
		}
	},
  computed: {
		tableHeaders() {
			return [
				{
					text: this.$t('payee'),
					sortable: false,
					value: "payee",
					class: "text-uppercase"
				},
				{
					text: this.$t('bill_no'),
					value: "billNumber",
					class: "text-uppercase"
				},
				{ 
					text: this.$t('due_date'),
					value: "dueDate"
				},
				{ 
					text: this.$t('open_balance'),
					value: "openBalance", 
					align: "right",
					class: "text-uppercase" 
				},
				{ 
					text: this.$t('type'),
					value: "payment", 
					align: "right", 
					width: 150,
					class: "text-uppercase"
				},
				{ 
					text: this.$t('total_amount'),
					value: "totalAmount", 
					align: "right",
					class: "text-uppercase"
				},
			];
		},
    showDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("toggle", value);
      },
    },
		balanceAmountFormatted() {
			return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.balanceAmount);
		},
		subTotal(){
			return this.selectedPayee.reduce((c, n) => c + Number(n.payment) , 0);
		},

		taxTotal() {
			return 0;
		}
  },

  methods: {
    onClose() {
      this.$emit("toggle");
    },

		onChangeVendor() {
			const vendorId = this.formData.vendor;
			this.payeeTableData = [
				{
					id: 1,
					payee: 'SHIFL CHINA',
					billNumber: 'SHFD2011253',
					dueDate: '01/01/2021',
					openBalance: 200.50,
					payment: '',
					totalAmount: 0,
				},
				{
					id: 2,
					payee: 'SHIFL CHINA',
					billNumber: 'SHFD2011239',
					dueDate: '01/01/2021',
					openBalance: 50,
					payment: '',
					totalAmount: 0,
				}
			];
		},

    onSaveForm() {
      this.$emit("toggle");
    },

  },
};
</script>

<style lang="scss" scoped>
	$button-bg-color: #0171a1;
	$form-label: #819FB2;
	.dialog {
		z-index: 9999;
	}
	.form-label{ 
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	hr {
		border-color: #ebf1f5;
	}

	th {
		color: $form-label;
		font-weight: bold;
	}

	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}

</style>

<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }

	.v-data-table-header {
		th {
			font-weight: bold;
		}
	}

	.text-right {
		input {
			text-align: right;
		}
	}
.z-index-front {
    z-index: 1;
  }
</style>
<style lang="scss">
	.app-theme-input-border{
		.v-input__slot {
			border: 1px solid #b5d0e1 !important;
		}
		label {
			color: #a3c6dd !important;
		}
	}
</style>