<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="70%"
      origin="top center"
      class="dialog pa-0"
			persistent
    >
      <v-card>
        <v-card-title class="pa-0 z-index-front">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>{{ $t('bill_payment') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
					<v-container class="grey lighten-4 pt-1">
						<v-form>
							<v-row
								no-gutters
							>
								<v-col
									cols="4"
									class="pl-4"
								>
									<v-card
										class="pa-2 grey lighten-4"
										outlined
										tile
									>
									<label class="form-label text-uppercase" for="formdata-bill-number">{{ $t('bill_no') }}</label>
									<v-text-field
										v-model="formData.billNumber"
										class="app-theme-input-border"
										label="Enter billing no."
										solo
										flat
										required
										dense
										id="formdata-bill-number"
									></v-text-field>

									<label class="form-label text-uppercase" for="formdata-payee">{{ $t('payee') }}</label>
									<v-select
										v-model="formData.payee"
										:items="payeeData"
										:rules="[v => !!v || $t('field_required')]"
										id="formdata-payee"
										:label="$t('select_payee')"
										solo
										flat
										class="app-theme-input-border"
										dense
									>
									</v-select>

									<label class="form-label text-uppercase" for="formdata-payment-account">{{ $t('payment_account') }}</label>
									<v-select
										v-model="formData.paymentAccount"
										:items="paymentAccountData"
										:rules="[v => !!v || $t('field_required')]"
										id="formdata-payment-account"
										:label="$t('select_payment_account')"
										solo
										flat
										class="app-theme-input-border"
										dense
									>
									</v-select>

									<label class="form-label text-uppercase" for="formdata-payment-date">{{ $t('payment_date') }}</label>
									<v-menu
										ref="paymentDateMenu"
										v-model="paymentDateMenu"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										max-width="290px"
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="formData.paymentDate"
												label="Date"
												persistent-hint
												append-icon="mdi-calendar"
												v-bind="attrs"
												v-on="on"
												id="formdata-bill-date"
												solo
												dense
												class="app-theme-input-border"
												flat
											></v-text-field>
										</template>
										<v-date-picker
											v-model="formData.paymentDate"
											no-title
											@input="paymentDateMenu = false"
										></v-date-picker>
									</v-menu>

									<label class="form-label text-uppercase" for="formdata-mailing-address">{{ $t('mailing_address') }}</label>
									<v-textarea
										solo
										:label="$t('type_mailing_address')+'...'"
										class="app-theme-input-border"
										flat
										id="formdata-mailing-address"
										rows="3"
									></v-textarea>

									<label class="form-label text-uppercase" for="formdata-notes">{{ $t('notes') }}</label>
									<v-textarea
										solo
										:label="$t('type_notes_here')+'...'"
										class="app-theme-input-border"
										flat
										id="formdata-notes"
										rows="3"
									></v-textarea>

									</v-card>
								</v-col>
								<v-col
									class="white pr-4 pl-4"
								>
									<v-card
										class="pa-2"
										flat
									>
										<table class="mb-0 w-100" dense>
												<thead class="grey lighten-3">
													<tr>
														<th class="pl-3 text-left text-uppercase text-subtitle-2 font-weight-black py-2">
															{{ $t('category') }}
														</th>
														<th class="pl-3 text-left text-uppercase text-subtitle-2 font-weight-black">
															{{ $t('description') }}
														</th>
														<th class="text-right text-uppercase text-subtitle-2 font-weight-black" width="15%">
															{{ $t('amount') }}
														</th>
														<th class="text-right text-uppercase text-subtitle-2 font-weight-black pr-2" width="15%">
															{{ $t('tax') }}
														</th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="item in formData.billCategories"
														:key="item.id"
													>
														<td class="pl-3 pt-2">
															{{ item.category }}
														</td>
														<td class="pl-3">
															{{item.description}}
														</td>
														<td class="px-0 pr-3 text-right">
															{{ item.amount }}
														</td>
														<td class="text-right pr-2">
															{{ item.tax }}
														</td>
													</tr>
												</tbody>
										</table>

										<v-row class="mt-4">
											<v-col cols="8">
												<label class="text-uppercase form-label">{{ $t('attachments') }}</label>
												<v-card
													class="text-center pa-5 border-dashed"
													width="400"
													flat
													@dragover="dragover"
													@dragleave="dragleave"
													@drop="drop"
													:class="[isBrowseFileHovered ? 'grey lighten-2' : '']"
												>
													<v-list
														subheader
														two-line
														v-if="fileList.length"
													>
														<v-subheader class="text-subtitle-1">{{ $t('files') }}</v-subheader>

														<v-list-item
															v-for="(file, index) in fileList"
															:key="file.name"
															dense
														>
															<v-list-item-avatar height="20">
																<v-icon
																	color="#0889a0"
																	v-text="'mdi-image'"
																></v-icon>
															</v-list-item-avatar>

															<v-list-item-content>
																<v-list-item-title v-text="file.name"></v-list-item-title>
															</v-list-item-content>

															<v-list-item-action>
																<v-btn icon @click="removeFile(index)">
																	<v-icon color="red">mdi-close</v-icon>
																</v-btn>
															</v-list-item-action>
														</v-list-item>
													</v-list>
													<input
														type="file"
														multiple
														name="filelist[images][]"
														id="file-input-make-payment" 
														class="display-none"
														@change="onFileBrowseChange"
														ref="filepaymentattachment"
														accept=".jpg,.jpeg,.png"
													/>
													<label for="file-input-make-payment" class="form-label pa-5" title="Click to browse file">
														<h6 class="pa-5">{{ $t('browse_drop_image') }}</h6>
													</label>
													<v-btn small outlined text class="text-capitalize pa-3" color="#0889a0" @click="onUploadFile">
														<v-icon>mdi-upload</v-icon>
														{{ $t('upload') }}
													</v-btn>
												</v-card>
											</v-col>
											<v-col>
												<v-row class="mb-8">
													<v-col class="text-right">{{ $t('subtotal') }}</v-col>
													<v-col class="text-right">${{ subTotal }}</v-col>
												</v-row>
												<hr>
												<v-row class="mt-2">
													<v-col class="text-right font-weight-bold">{{ $t('total') }}</v-col>
													<v-col class="text-right font-weight-bold">${{ subTotal + taxTotal }}</v-col>
												</v-row>
												<v-row class="mt-8">
													<v-col class="text-right">
														<label class="form-label text-uppercase" for="formdata-amount">{{ $t('amount') }}</label>
														<v-text-field
															v-model="formData.payAmount"
															:label="$t('enter_amount_to_pay')"
															solo
															class="app-theme-input-border"
															flat
															required
															dense
															id="formdata-amount"
														></v-text-field>
													</v-col>
												</v-row>
											</v-col>
										</v-row>
																				
									</v-card>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-start">
          <v-btn @click="onSaveForm" class="text-capitalize btn-primary">{{ $t('pay') }}</v-btn>
					<v-btn outlined text class="text-capitalize" @click="onClose">{{ $t('cancel') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["open"],
	data() {
		return {
			formData: {
				billCategories: [{
					id: 1,
					category: 'Freight',
					description: '--',
					amount: 0,
					tax: 0,
				}],
			},
			paymentDateMenu: false,
			billDueDateMenu: false,
			payeeData: [{
				value: 1,
				text: 'Payee Name',
			}],
			paymentAccountData: [{
				value: 1,
				text: '1 Year'
			}],
			categoryData: [],
			taxData: [],
			fileList: [],
			isBrowseFileHovered: false,
		}
	},
  computed: {
    showDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("toggle", value);
      },
    },
		subTotal(){
			if(this.formData.billCategories.length) {
				return this.formData.billCategories.reduce((c, n) => c + Number(n.amount) ,0);
			}
			return 0;
		},

		taxTotal() {
			if(this.formData.billCategories.length) {
				return this.formData.billCategories.reduce((c, n) => c + Number(n.tax) ,0);
			}
			return 0;
		}
  },

  methods: {
    onClose() {
      this.$emit("toggle");
    },

    onSaveForm() {
      this.$emit("toggle");
    },

		onAddCategory() {
			this.formData.billCategories.push({
				id: Date.now(),
				category: '',
				description: '',
				amount: 0,
				tax: 0,
			});
		},

		onRemoveCategory(id) {
			this.formData.billCategories = this.formData.billCategories.filter( record =>  record.id !== id);
		},

		onFileBrowseChange() {
      this.fileList = [...this.$refs.filepaymentattachment.files];
    },

    removeFile(i) {
      this.fileList.splice(i, 1);
    },

    dragover(event) {
      event.preventDefault();
      this.isBrowseFileHovered = true;
    },

    dragleave(event) {
      this.isBrowseFileHovered = false;
    },

    drop(event) {
      event.preventDefault();
      this.$refs.filepaymentattachment.files = event.dataTransfer.files;
      this.onFileBrowseChange();
      this.isBrowseFileHovered = false;
    },

		onUploadFile() {
			// Upload to server here
			console.log('Uploading...');
		}
  },
};
</script>

<style lang="scss" scoped>
	$form-label: #819FB2;
	$button-bg-color: #0171a1;
	.dialog {
		z-index: 9999;
	}
	.form-label{ 
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	hr {
		border-color: #ebf1f5;
	}

	th {
		color: $form-label;
		font-weight: bold;
	}

	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}

	.border-dashed {
		border: 1px dashed $form-label;
	}

	.z-index-front {
    z-index: 1;
  }

</style>
<style lang="scss">
	.app-theme-input-border{
		.v-input__slot {
			border: 1px solid #b5d0e1 !important;
		}
		label {
			color: #a3c6dd !important;
		}
	}
</style>