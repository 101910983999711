<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="inputValue"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="inputValue"
        append-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        class="app-theme-input-border"
        outlined
        dense
        placeholder="time"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="inputValue"
      full-width
      @click:minute="$refs.menu.save(inputValue)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      menu: false
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.app-theme-input-border{
		.v-input__slot {
			border: 1px solid #b5d0e1 !important;
		}
		label {
			color: #a3c6dd !important;
		}
	}
</style>
