<template>
  <div class="widgetContainer__body">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">{{ $t('bills') }}</p>
      </div>
    </div>
    <v-app class="vue-app">
      <v-main>
        <bill-transaction-table ref="billingTransactionTable"></bill-transaction-table>
      </v-main>
    </v-app>
    <!-- <v-btn absolute bottom right dark style="right: 20px">test</v-btn> -->
  </div>
</template>

<script>
import BillTransactionTable from '../components/BillTransactionTable.vue';

export default {
	components: {
		BillTransactionTable,
	},
  created() {
    //
  },
  data() {
    return {
      fab: false
    };
  },
  computed: {
  },
  methods: {
    //
  }

};
</script>
<style lang="scss" scoped>
.vue-app {
  background: none;
}

</style>