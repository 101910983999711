<template>  
  <v-container fluid>
    <v-data-table
      :headers="tableHeaders"
      :items="transactionData"
      class="elevation-1 v-table-middle-align"
      :single-select="false"
      show-select
      v-model="selected"
      hide-default-footer
      :loading="isBillDataLoading"
    >
      <template v-slot:top>
        <div class="d-md-flex w-100 pt-4 px-4 pb-3 border-bottom">
          <div class="d-md-flex justify-md-end my-2 my-md-0 mx-md-0">
            <div class="d-flex">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" outlined large height="40" class="text-capitalize" color="primary">
                    {{ $t('status') + (selectedStatus ? ': ' + selectedStatus.toUpperCase() : '') }}
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-card width="300">
                  <v-card-text>
                    <v-list dense class="py-0">
                      <v-list-item-group
                        v-model="selectedStatus"
                        color="primary"
                        @change="fetchBills()"
                      >
                        <v-list-item :value="''">
                          <v-list-item-content>
                            <v-list-item-title>NONE</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="status in billStatusList"
                          :key="status"
                          :value="status"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="status" class="text-uppercase"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
              <div class="search-field-wrapper ml-2">
                <v-text-field
                  :label="$t('bill_search_no')"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  hide-details
                  flat
                  outlined
                  solo
                  dense
                  v-model="searchText"
                  color="primary"
                ></v-text-field>
              </div>
            </div>
            <div class="d-md-block d-none ml-2">
              <v-btn
                @click="onToggleBillingForm"
                class="text-capitalize"
                color="primary"
                height="38"
              >
                {{ $t('add_bill') }}
              </v-btn>
            </div>
            <div class="d-sm-block d-md-none mt-2">
              <v-btn
                @click="onToggleBillingForm"
                class="text-capitalize d-block"
                color="primary"
                height="38"
                width="100%"
              >
                {{ $t('add_bill') }}
              </v-btn>
            </div>
          </div>          
        </div>
      </template>
      <template v-slot:[`item.document_number`]="{item}">
        <a href="#" @click.prevent="$router.push(`/accounting/${ item.type === 'bill' ? 'bills' : 'expenses' }/${item.id}`)">
          <span>{{ item.document_number }}</span>
        </a>
      </template>
      <template v-slot:[`item.issued_at`]="{ item }">
        <h6 v-text="convertDate(item.issued_at)"/>
      </template>
      <template v-slot:[`item.due_at`]="{ item }">
        <h6 v-text="convertDate(item.due_at)"/>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip dark :color="itemStatus(item.status)" v-text="item.status" small class="text-uppercase"/>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined small>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                v-if="item.status === 'draft'"
              >
                <v-list-item-title class="text-primary" @click="onToggleMarkReceivedForm(item)">{{ $t('billing_mark_receive') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="onTransactionSelected( item.type === 'bill' ? 0 : 1 , item)"
              >
                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="$router.push(`/accounting/${ item.type === 'bill' ? 'bills' : 'expenses' }/${item.id}`)"
              >
                <v-list-item-title>{{ $t('view') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                v-if="item.type === 'Expense'"
              >
                <v-list-item-title>{{ $t('print') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                v-if="item.type === 'Expense'"
              >
                <v-list-item-title>{{ $t('void') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
              >
                <v-list-item-title class="text-red" @click=" item.type === 'bill' ? onToggleDeleteBill(item) : onSelectDataToDelete(item)">{{ $t('delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
      </template>
      <template v-slot:no-data>
        <v-alert>
          <h4>{{ $t('no_data') }}</h4>
          <v-btn text small @click="onToggleBillingForm" color="#0889a0" class="mb-2 font-weight-bold py-4 text-capitalize">
            <v-icon>mdi-plus</v-icon>
            {{ $t('add_bill') }}
          </v-btn>
        </v-alert>
      </template>
      <template v-slot:foot>
        <tfoot>
          <tr>
            <td colspan="9" class="text-right">
              <div class="d-flex justify-md-end justify-lg-end justify-center">
                <!-- <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{pagination.current_page || 0}} - {{ pagination.total_pages || 0 }} {{ $t('of') }} {{ (pagination.total || 0).toLocaleString() }} <span class="d-sm-inline d-none">{{ $t('records') }}</span></h6> -->
                <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{ (pagination.total || 0).toLocaleString() }} <span class="">{{ $t('records') }}</span></h6>
                <v-spacer/>
                <div class="select-page-limit">
                  <v-select
                    v-model="pageLimit"
                    :items="[10, 20, 25]"
                    label="Per page"
                    hide-details
                    dense
                    flat
                    solo
                    @change="onChangePageLimit"
                  ></v-select>
                </div>
                <pagination
                  :total="pagination.total_pages || 1"
                  :current-page="currentPage"
                  :total-visible="10"
                  @pageSelected="onPaginationClick"
                >
                </pagination>
              </div>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
    <v-snackbar
      timeout="2000"
      vertical
      :color="snackbarOption.color"
      v-model="showSnackbar"
      bottom
    >
      <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
    </v-snackbar>
    <billing-form :open="showBillingFormDialog" :form-values="selectedBillingData" :is-edit-mode="isFormEditMode" @toggle="onToggleBillingForm"></billing-form>
    <!-- <expense-form :open="showExpenseFormDialog" :form-values="formDataSelected" :is-edit-mode="isFormEditMode" @toggle="onTransactionSelected(1)"></expense-form>
    <payment-bills-form :open="showPaymentBillFormDialog" @toggle="onTransactionSelected(2)"></payment-bills-form>
    <bill-make-payment-form :open="showMakePaymentFormDialog" @toggle="showMakePaymentFormDialog = !showMakePaymentFormDialog"></bill-make-payment-form> -->
    <bill-delete :open="showDeleteBillModal" :form-data="selectedBillingData" @toggle="onToggleDeleteBill"></bill-delete>
    <bill-received :open="showMarkReceivedModal" :form-data="selectedBillingData" @toggle="onToggleMarkReceivedForm"></bill-received>
    <expense-delete :open="showDeleteExpenseModal" :form-data="formDataToDelete" @toggle="showDeleteExpenseModal = !showDeleteExpenseModal"></expense-delete>
  </v-container>
</template>

<script>

import { apiErrorMessage, debounce } from '@/utils/general';

import { mapActions } from 'vuex';

import BillingForm from "./BillingForm.vue";
import ExpenseForm from './ExpenseForm.vue';
import PaymentBillsForm from './PayBillsForm.vue';
import BillMakePaymentForm from './BillMakePaymentForm.vue';
import BillDelete from './BillingDeleteModal.vue';
import BillReceived from './BillMarkReceivedModal.vue';
import ExpenseDelete from './ExpenseDeleteModal.vue';
import Pagination from './Pagination.vue';
import moment from 'moment'

export default {
  components: {
    BillingForm,
    ExpenseForm,
    PaymentBillsForm,
    BillMakePaymentForm,
    BillDelete,
    BillReceived,
    ExpenseDelete,
    Pagination,
  },
  data: () => ({
    showBillingFormDialog: false,
    showExpenseFormDialog: false,
    showPaymentBillFormDialog: false,
    showMakePaymentFormDialog: false,
    showDeleteBillModal: false,
    showDeleteExpenseModal: false,
    showMarkReceivedModal: false,
    isFormEditMode: false,
    dialogDelete: false,
    formDataSelected: {},
    formDataToDelete: {},
    searchText: "",
    selected: [],
    transactionMenu: [
      { title: "Add Bill" },
      { title: "Add Expense" },
      { title: "Pay Bills" },
    ],
    filterBy: '',
    selectedStatus: '',
    currentPage: 1,
    pageLimit: 10,
    snackbarOption: {
      icon: '',
      message: '',
      color: ''
    },
    showSnackbar: false,
    isDataLoading: false,
    selectedBillingData: null,
    billsData: [],
    isBillDataLoading: false,
    billStatusList: [
      'draft',
      'received',
      'partial',
      'paid',
      'overdue',
      'unpaid',
      'cancelled',
    ],
  }),

  created() {
    this.fetchBills();
  },

  watch: {
    searchText: debounce(function() {
      this.fetchBills();
    }, 300)
  },

  computed: {
    // ...mapState('accounting', ['billsData', 'isBillDataLoading']),
    tableHeaders() {
      return [
        {
          text: this.$t('number'),
          value: "document_number",
          class: "text-uppercase th--text"
        },
        {
          text: this.$t('vendor'),
          align: "center",
          sortable: false,
          value: "contact.data.name",
          class: "text-uppercase th--text"
        },
        {
          text: this.$t('pay_row_amount'),
          value: "amount_formatted",
          class: "text-uppercase th--text",
          align: 'right'
        },
        {
          text: this.$t('bill_date'),
          value: "issued_at",
          class: "text-uppercase th--text",
          align: 'center'
        },
        {
          text: this.$t('due_date'),
          value: "due_at",
          class: "text-uppercase th--text",
          align: 'center'
        },
        // {
        //   text: this.$t('total'),
        //   value: "total",
        //   class: "text-uppercase"
        // },
        {
          text: this.$t('status'),
          value: "status",
          class: "text-uppercase th--text",
          align: 'center'
        },
        { text: "", value: "actions", sortable: false },
      ];
    },

    transactionData: {
      get() {
        return this.billsData?.data || [];
      }
    },

    pagination: {
      get() {
        return this.billsData.data ? this.billsData.meta.pagination : {};
      }
    },
  },

  methods: {
    ...mapActions('accounting', ['getBillsData']),
    moment,
    itemStatus(status){
      switch(status.toLowerCase()){
        case 'received': return 'red darken-4';
        case 'paid': return 'green darken-4';
        case 'draft': return 'purple darken-4';
        case 'partial': return 'blue darken-4';
        case 'cancelled': return 'black';
      }
    },
    convertDate(val){
      // return moment(val).format('DD MMM YYYY, h:mm A')
      return moment(val).format('DD MMM YYYY')
    },

    async fetchBills(filters = {}) {
      if(this.isBillDataLoading) {
        return;
      }
      this.isBillDataLoading = true;
      try {

        const data = await this.getBillsData({
          ...filters,
          page: this.currentPage,
          limit: this.pageLimit,
          search: this.searchText || '',
          status: this.selectedStatus,
        });

        this.billsData = data;
        this.isBillDataLoading = false;
      } catch(error) {
        this.isBillDataLoading = false;
        apiErrorMessage(error);
      }
    },

    onPaginationClick(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchBills();
    },

    onChangePageLimit() {
      this.currentPage = 1;
      this.fetchBills();
    },

    currencyDollarFormat(number = 0) {
      return new Intl.NumberFormat('en-US').format(number);
    },

    onSelectDataToDelete(data = {}) {
      this.showDeleteExpenseModal = false;
      this.showDeleteBillModal = false;
      if(data.type === 'Bill') {
        this.showDeleteBillModal = true;
      } else if(data.type === 'Expense') {
        this.showDeleteExpenseModal = true;
      }
      this.formDataToDelete = {...data};
    },

    onSelectselectedStatus() {
      this.fetchBills();      
    },

    onToggleBillingForm(options = {}) {
      this.showBillingFormDialog = !this.showBillingFormDialog;
      if(options.created || options.updated) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'success',
					message: options.message,
				};
				this.showSnackbar = true;
        this.isFormEditMode = false;
        this.fetchBills();
        this.selectedBillingData = null;
      }
      if(!this.showBillingFormDialog) {
        this.isFormEditMode = false;
      }
    },

    onToggleDeleteBill(data = null, options = {}) {
      this.selectedBillingData = JSON.parse(JSON.stringify(data));
      this.showDeleteBillModal = !this.showDeleteBillModal
      if(options.deleted) {
        this.snackbarOption = {
					icon: 'mdi-delete',
					color: 'red',
					message: options.message || 'Deleted',
				};
        this.fetchBills();
        this.showSnackbar = true;
      }
    },
    
    onTransactionSelected(index, data = null) {
      this.selectedBillingData = null;
      this.formDataSelected = data;

      this.isFormEditMode = data !== null;

      if(index === 0) {
        this.selectedBillingData = JSON.parse(JSON.stringify(data));
        this.onToggleBillingForm({});
      }


      if(index === 1) {
        this.showExpenseFormDialog = !this.showExpenseFormDialog;
      }

      if(index === 2) {
        this.showPaymentBillFormDialog = !this.showPaymentBillFormDialog;
      }

    },

    onToggleMarkReceivedForm(data = null, options = {}) {
      this.selectedBillingData = JSON.parse(JSON.stringify(data));
      this.showMarkReceivedModal = !this.showMarkReceivedModal;
      if(options.received) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'green',
					message: options.message || 'Mark as received.',
				};
        this.fetchBills();
        this.showSnackbar = true;
      }
    }

  },
};
</script>

<style lang="scss" scoped>
  $form-color: #0889a0;
  $btn-active-color: #0171a1;
  $text-muted-color: #6d858f;
  .text-green {
    color: #07a107be;
  }

  .text-red {
    color: red;
  }

  .form-label {
    color: $form-color;
  }

  .form-border {
    border: 1px solid;
    border-color: $form-color !important;
  }

  .btn-active-color:active {
    background-color: $btn-active-color;
    color: #fff;
  }

  .text-muted {
    color: $text-muted-color;
  }

  .v-btn.v-item--active.v-btn--active {
    color: $btn-active-color;
  }

  .search-field-wrapper{
    max-width: 400px;
  }

  .text-white {
    color: #fff;
  }

  .text-primary {
    color: var(--shifl-pressed-blue);
  }

  .v-list-item {
    min-height: 36px;
  }

  .btn-primary {
    background-color: $btn-active-color !important;
    color: #ffffff !important;
  }
  .select-page-limit {
    width: 80px;
  }
  .border-bottom {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

</style>

<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }
</style>