<template>
    <v-pagination
      v-model="page"
      :length="total"
      :total-visible="totalVisible || 5"
      color="primary"
      previous-aria-label="Previous"
      next-aria-label="Next"
      @input="itemClicked"
    />
</template>
<script>
export default {
    props: [
        'total', 'currentPage', 'totalVisible'
    ],
    methods:{
        itemClicked(val){
            this.$emit('pageSelected', val)
        }
    },
    computed: {
        page: {
            get() {
                return this.currentPage;
            },
            set(value) {
                this.$emit('pageSelected', value);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
$primary: #0171a1;
    ::v-deep .v-pagination__item, ::v-deep .v-pagination__navigation{
        box-shadow: none !important;
        color: $primary !important;
        background-color: transparent !important;
        font-weight: bold;
        .v-icon{
            color: $primary
        }
    }
    ::v-deep .v-pagination__item--active{
        color: black !important;
        font-size: 1.1rem !important;
    }
</style>